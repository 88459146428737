import {useCatalogStore} from '../stores/CatalogStore.js';

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (process.server || (from.name && typeof from.name === 'string' && !from.name.startsWith('search'))) {

        let navigateUrl = null;
        await useCatalogStore().fetchCatalog(to.query.term).then(data => {
            // Open page: /en/search/?type_ids[0]=2&metal_ids[0]=1
            // Catalog exists, need navigateTo: /en/gold-coins/
            let url = data?.catalog?.url;
            if (url && !url.includes('/search/')) {
                navigateUrl = url;
            }
        });

        if (navigateUrl) {
            return navigateTo(navigateUrl);
        }
    }
});
